import Link from "next/link";
import Image from "next/image";

const TradeSmartWin = () => {
    const data = [
        {
            text: "Join Discord Community",
            link: "https://discord.com/invite/fundednext-945622549373526056",
            image: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/discord-new.svg"
        },
        {
            text: "Join X Community",
            link: "https://x.com/FundedNext",
            image: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/twitter_new.svg"
        }
    ];

    return (
        <>
            {/* <div className="flex justify-center">
                <CoinCircle />
            </div> */}
            {/* <div className="flex justify-center"> */}
            {/* <AnimatedCapsule text="Start Your Trading Journey Now" /> */}
            {/* </div> */}

            <div className=" mx-auto w-full max-w-[1216px] py-[50px] xl:pb-[85px] xl:pt-[150px]">
                <p className="text-center font-Rebond text-[28px] font-bold leading-[60px] text-white xl:text-[72px]">
                    Trade Smart & Win
                </p>
                <p className="pt-3 text-center text-sm leading-[23px] text-[rgba(255,255,255,0.60)] md:mx-auto md:w-1/2 xl:w-full  xl:text-[20px] xl:leading-[34px]">
                    Maximize your trading success with FundedNext: Trade up to
                    $300,000.
                </p>
                <div className="mt-10 flex w-full  flex-col items-center justify-center gap-5 lg:flex-row">
                    {data?.map((item, index) => {
                        return (
                            <Link
                                href={item.link}
                                key={index}
                                className="flex w-[325px] items-center justify-center gap-x-[10px] rounded-[50px] px-10 py-4"
                                style={{
                                    background:
                                        index === 0
                                            ? "linear-gradient(90deg, #8B55FF -0.15%, #4D19E0 99.85%)"
                                            : " linear-gradient(90deg, #29A3EE -0.16%, #0067DF 99.84%)"
                                }}
                            >
                                <Image
                                    src={item.image}
                                    alt="Logo"
                                    height={25}
                                    width={25}
                                />

                                <span className="text-[13px] font-[500] text-white xl:text-[18px]">
                                    {item.text}
                                </span>
                            </Link>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default TradeSmartWin;