import React from "react";

const TitleHome = ({ content }) => {
    const { title, description, button, customStyle } = content;
    return (
        <div className={`${customStyle}`}>
            <p
                className={`text-center font-Rebond text-[24px] font-[600] text-white lg:text-[56px] lg:leading-[70px] ${title?.customStyle}`}
            >
                {title?.text}
            </p>
            {description?.text && (
                <p
                    className={`mt-3 text-center font-Inter text-sm font-normal leading-[22px] text-[rgba(255,255,255,0.60)] lg:text-[18px] lg:font-normal  xl:leading-[28px] ${description?.customStyle}`}
                >
                    {description?.text}
                </p>
            )}
        </div>
    );
};

export default TitleHome;
