import { useRouter } from "next/router";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPlanName } from "../../../../redux/slices/choosePlanSlice";
import CustomImage from "../../common/CustomImage";
import AnimatedCapsule from "../../common/ui/AnimatedCapsule";
import MarkIcon from "../../icon/MarkIcon";
import TitleHome from "./TitleHome";
const PricingTableNew = () => {
    const dispatch = useDispatch();
    const choosePlan = useSelector((state) => state.choosePlan);
    const titleInfo = {
        button: {
            btn_title: "Start Challenge Now",
            btn_link: "/about-us"
        },
        title: {
            text: "FundedNext Challenges",
            customStyle: ""
        },
        description: {
            text: "The most popular packages amongst FundedNext traders",
            customStyle: " lg:w-8/12 mx-auto"
        },
        customStyle: "lg:w-10/12 mx-auto"
    };

    const pricingContent = [
        {
            challengeName: "Stellar",
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/steller-ch.svg",
            description: "The most popular package amongst FundedNext traders",
            category: ["1-Step", "2-Step"],
            pricingRange: [
                {
                    info: "Account sizes from",
                    price: "$6K-$200K"
                }
            ],
            benefits: [
                "Upto 95% Profit Split",
                "15% Profit Share in Challenge Phase",
                "Up to 150% Reward",
                "No Time Limit",
                "News Trading Allowed"
            ],
            btn_link: "/plan/stellar"
        },
        {
            challengeName: "Evaluation",
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/eva-ch.svg",
            description: "The only package that offers free retakes",

            pricingRange: [
                {
                    info: "Account sizes from",
                    price: "$6K-$200K"
                }
            ],
            benefits: [
                "Upto 95% Profit Split",
                "15% Profit Share in Challenge Phase",
                "Up to 10% Maximum Loss Limit",
                "Up to 150% Reward",
                "News Trading Allowed"
            ],
            btn_link: "/plan/evaluation"
        },
        {
            challengeName: "Express",
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/express-ch.svg",
            description:
                "The package offers profit shares without needing 5% growth",

            pricingRange: [
                {
                    info: "Consistency",
                    price: "$6K-$200K"
                },
                {
                    info: "Non-consistency",
                    price: "$6K-$100K"
                }
            ],
            benefits: [
                "Upto 95% Profit Split",
                "15% Profit Share in Challenge Phase",
                "Up to 10% Maximum Loss Limit",
                "Up to 150% Reward",
                "News Trading Not Allowed"
            ],
            btn_link: "/plan/express"
        },
        {
            challengeName: "Stellar Lite",
            icon: "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/steller-lite-ch.svg",
            description:
                "The most affordable package for new and upcoming traders",

            pricingRange: [
                {
                    info: "Account sizes from",
                    price: "$5K-$50K"
                }
            ],
            benefits: [
                "Upto 95% Profit Split",
                "Super Affordable",
                "Up to 10% Maximum Loss Limit",
                "Lowest Profit Target",
                "News Trading Allowed"
            ],
            btn_link: "/plan/stellar-lite"
        }
    ];
    const pricingStyle = {
        0: {
            color: "#A68FCA",
            bg: "radial-gradient(244.14% 100% at 50% 0%, #7806AE 0%, #2A054F 45%)",
            overlay:
                "radial-gradient(244.14% 100% at 50% 0%, #7806ae 0%, #2a054f 60%)",
            className: "stellar-challenge"
        },
        1: {
            color: "#A2A1D4",
            bg: "radial-gradient(244.14% 100% at 50% 0%, #593DC7 0%, #1B0A49 45%)",
            className: "evaluation-challenge",
            overlay:
                "radial-gradient(244.14% 100% at 50% 0%,#593dc7 0%,#1b0a49 60%)"
        },
        2: {
            color: "#98ACCB",
            bg: "radial-gradient(244.14% 100% at 50% 0%, #0C5EC1 0%, #080F3F 45%)",
            className: "express-challenge",
            overlay:
                "radial-gradient(244.14% 100% at 50% 0%,#0c5ec1 0%,#080f3f 60%)"
        },
        3: {
            color: "#AAAFC5",
            bg: "radial-gradient(244.14% 100% at 50% 0%, #3D4177 0%, #1D1D32 45%)",
            className: "stellar-lite-challenge",
            overlay:
                "radial-gradient(244.14% 100% at 50% 0%,#3d4177 0%,#1d1d32 60%)"
        }
    };
    const router = useRouter();

    // this function is for ab testing we can handle logic here for displaying the plan in the home or extra route
    const handleRoute = (routeLink) => {
        const planName = routeLink?.split("/")[2];
        dispatch(setPlanName(planName ?? "stellar"));
        // we have to conditionally render here for AB Testing
        // router.push(`${routeLink}`);
        document
            ?.getElementById("choose-plan")
            ?.scrollIntoView({ behavior: "smooth" });
    };
    return (
        <section
            id="start-challenge"
            className=" relative bg-[black] py-[60px] xl:py-[160px]"
        >
            <div className=" container-v2">
                <div className="mb-4 flex justify-center">
                    <AnimatedCapsule text="Start a Challenge" />
                </div>
                <TitleHome content={titleInfo} />
            </div>
            <div className="hide-scroll-bar w-full overflow-y-hidden overflow-x-scroll">
                <div
                    id="pricing"
                    className="flex w-full gap-x-5 px-5  pb-3 pt-16 md:px-[60px] lg:w-[120%] xl:mx-auto  xl:w-auto xl:max-w-[1212px] xl:px-5"
                >
                    {pricingContent?.map((challenge, index) => {
                        return (
                            <div
                                className={` group  relative w-[350px] overflow-visible rounded-[16px] bg-gradient-to-b  from-[rgba(255,255,255,0.04)] to-[rgba(255,255,255,0.10)] p-[0px] lg:mt-20 lg:w-[350px]  ${index === 0 && "mr-[6px]     "}`}
                                key={index}
                            >
                                {index === 0 && (
                                    <div
                                        className="absolute -top-[42px] left-[-6px] z-[-1px] h-[calc(100%+48px)] w-[calc(100%+12px)]  rounded-[22px]"
                                        style={{
                                            background:
                                                "linear-gradient(180deg, #8000FF -1.15%, #AD5BFF 9.33%)"
                                        }}
                                    >
                                        <p
                                            style={{
                                                textEdge: "cap",
                                                letterSpacing: "2.24px",
                                                leadingTrim: "both"
                                            }}
                                            className="py-[14px] text-center text-sm font-semibold  uppercase text-[rgba(255,255,255,0.75)] lg:text-white"
                                        >
                                            Popular Plan
                                        </p>
                                    </div>
                                )}
                                <div
                                    className={`relative h-full w-[270px] rounded-[16px]  lg:w-full  ${pricingStyle[index]?.className}`}
                                >
                                    <div
                                        className="absolute left-0 right-0 h-[100%] w-[100%] rounded-[16px] opacity-0 transition-all duration-300 ease-linear group-hover:opacity-100"
                                        style={{
                                            background:
                                                pricingStyle[index]?.overlay
                                        }}
                                    ></div>
                                    <div className="relative">
                                        <div
                                            className={`relative min-h-[240px] w-full p-5 pb-0 lg:min-h-[235px]`}
                                        >
                                            <CustomImage
                                                src={challenge?.icon}
                                                alt=""
                                                height={40}
                                                width={40}
                                                layout={"fixed"}
                                            />
                                            <p className="pb-1 pt-2 text-[32px] font-semibold text-white lg:pt-[6px] lg:text-[34px]">
                                                {challenge?.challengeName}
                                            </p>
                                            <p
                                                className={`font-Inter text-sm font-[400]`}
                                                style={{
                                                    color: pricingStyle[index]
                                                        ?.color
                                                }}
                                            >
                                                {challenge?.description}
                                            </p>

                                            <div className="absolute bottom-[33.5px] left-0 h-[1px] w-full bg-gradient-to-r from-transparent via-[#ffffff12] to-transparent" />
                                        </div>
                                        <ul className="flex min-h-[260px] flex-col gap-y-[12px] px-5 lg:min-h-[240px] xl:min-h-[240px]">
                                            {challenge?.benefits?.map(
                                                (benefit, idx) => {
                                                    return (
                                                        <li
                                                            key={idx}
                                                            className="flex items-start gap-x-4"
                                                        >
                                                            <span>
                                                                {" "}
                                                                <MarkIcon />
                                                            </span>
                                                            <p
                                                                className="font-Inter text-sm font-[400]  "
                                                                style={{
                                                                    color: pricingStyle[
                                                                        index
                                                                    ]?.color
                                                                }}
                                                            >
                                                                {benefit}
                                                            </p>
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                        <div className="flex items-center justify-center">
                                            <button
                                                className="flex h-[70px] w-full items-center justify-center rounded-bl-[16px] rounded-br-[16px] font-Inter text-base font-semibold text-white shadow-planCard"
                                                style={{
                                                    background:
                                                        "rgba(255, 255, 255, 1)",

                                                    background:
                                                        "rgba(255, 255, 255, 0.03)"
                                                }}
                                                onClick={() =>
                                                    handleRoute(
                                                        challenge?.btn_link
                                                    )
                                                }
                                            >
                                                <span className="">
                                                    {" "}
                                                    Start Challenge{" "}
                                                </span>{" "}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default PricingTableNew;
