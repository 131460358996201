import { useGSAP } from "@gsap/react";
import React, { useRef, useState } from "react";
import { ThreeDIcon } from "../../../SVGIcons/SVGIcons";
import ModalUpdated from "../../common/modalUpdated";
import gsap from "gsap";

const OfficeTour = () => {
    // Animation initialization start
    const container = useRef(null);
    useGSAP(
        () => {
            setTimeout(() => {
                const pad = gsap.matchMedia();
                pad.add("(min-width: 992px)", () => {
                    gsap.to(".border-anime-office", {
                        rotate: 100,
                        scrollTrigger: {
                            trigger: container.current,
                            start: "bottom 70%",
                            end: "bottom 30%",
                            scrub: 3,
                            ease: "none",
                            onLeave: () => {
                                gsap.to(".border-anime-office", {
                                    rotate: 115,
                                    opacity: 0,
                                    duration: 1
                                });
                            },
                            onEnterBack: () => {
                                gsap.to(".border-anime-office", {
                                    rotate: 100,
                                    opacity: 1,
                                    duration: 1
                                });
                            },
                            toggleActions: "restart none none play"
                        }
                    });
                });
            }, 300);
        },
        { scope: container }
    );
    // Animation functionality End

    const states = [
        {
            number: "350+",
            text: "Dedicated professionals"
        },
        {
            number: "24/7",
            text: "Customer Support"
        },
        {
            number: "4 Offices",
            text: "Around the world"
        }
    ];
    const gradients = [
        "linear-gradient(180deg, #8B55FF 0%, #4D19E0 100%)",
        "linear-gradient(180deg, #56E8FF 0%, #0167E0 100%)",
        "linear-gradient(180deg, #FF69BA 0%, #D40140 100%)"
    ];

    const [isOpen, setIsOpen] = useState(false);
    return (
        <section
            className="relative z-0 mt-5 overflow-hidden  rounded-[10px] xl:rounded-[40px] xl:p-7"
            ref={container}
        >
            <div className="border-anime-office border-gradient-bottom-right pad:opacity-1 absolute z-[-1] rounded-[40px] opacity-0"></div>
            <div className="opacity-1 absolute left-[50%] top-[50%] z-[-1] h-[97.5%] w-[99%] translate-x-[-50%] translate-y-[-50%] rounded-[35px] bg-black"></div>
            <div className="opacity-1 flex w-full flex-col-reverse flex-wrap gap-y-[30px] rounded-[20px] bg-mirage p-5 xl:flex-row xl:p-20">
                <div className="h-auto w-full  xl:w-7/12">
                    <p className="text-[28px] font-semibold leading-10 text-white xl:text-[34px] xl:leading-[47px]">
                        Collaborative Venture <br /> Powered by Expertise
                    </p>
                    <p className="pb-[50px] pt-5 font-Inter text-sm font-normal  leading-[28px] text-[rgba(255,255,255,0.60)] xl:w-10/12 xl:text-base">
                        Take a tour and step inside our dynamic workspace, and
                        experience the place where the magic happens.
                    </p>

                    <div className="flex gap-x-4   pb-5 lg:pb-0 xl:gap-x-0 2xl:w-9/12">
                        {states.map((state, index) => {
                            return (
                                <div
                                    key={index}
                                    className=" relative   pl-3 lg:pl-4"
                                >
                                    <div
                                        className="absolute left-0 top-0 h-full w-[4px] rounded"
                                        style={{
                                            background:
                                                gradients[
                                                    index % gradients.length
                                                ]
                                        }}
                                    />
                                    <p className=" text-[14px] font-medium text-white xl:text-[22px]">
                                        {state.number}
                                    </p>
                                    <p className="w-full pt-1 font-Inter text-xs font-normal !leading-[16px] text-[rgba(255,255,255,0.50)]  lg:w-8/12  xl:text-base">
                                        {state.text}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="full relative flex  items-center justify-end  py-6 xl:w-5/12">
                    <div className="relative h-full w-full">
                        <img
                            loading="lazy"
                            src="https://fundednext.fra1.cdn.digitaloceanspaces.com/office-tour-cover-mobile.webp"
                            className="h-full w-full rounded-lg md:hidden"
                            alt="office tour"
                        />
                        <img
                            loading="lazy"
                            src="https://fundednext.fra1.cdn.digitaloceanspaces.com/tour-guide-cover-photo.webp"
                            className="hidden h-full w-full rounded-lg border-[1px] border-[#ffffff80] md:block"
                            alt="office tour"
                        />
                        <div
                            className={` left-0 top-0 z-20 h-full w-full sm:container`}
                        >
                            <button
                                onClick={() => setIsOpen(true)}
                                id="funded-next-tour"
                                className="absolute inset-0 my-2 flex w-full flex-col items-center justify-center"
                            >
                                <span
                                    id="play-video"
                                    className="video-play-button -mt-8 cursor-pointer"
                                >
                                    <img
                                        src="https://fundednext.fra1.digitaloceanspaces.com/play-icon.svg"
                                        alt="play icon"
                                    />
                                </span>

                                <div className="">
                                    <ThreeDIcon className="mt-32" />
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <ModalUpdated visible={isOpen} handleClose={() => setIsOpen(false)}>
                <iframe
                    src="https://office-tour.fundednext.com/"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    className="min-h-[360px] w-full rounded-[8px]"
                />
            </ModalUpdated>
        </section>
    );
};

export default OfficeTour;
